import * as React from "react";
import Card from "./card/Card.component";
import * as style from "./CardList.style.module.scss";

const CardList = ({ cards }) => {
  return (
    <div className={style.card_list}>
      {cards.map((card) => (
        <Card key={card.id} card={card} />
      ))}
    </div>
  );
};
export default CardList;
