import * as React from "react";
import * as style from "./Section.style.module.scss";

const Section = ({ children, title }) => {
  return (
    <section className={style.section}>
      {title !== ''?<h2 className={style.title}>{title}</h2>: null}
      {children}
    </section>
  );
};

export default Section;
