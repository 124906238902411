import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import * as style from "./Card.style.module.scss";

const Card = ({ card }) => {
  const image = getImage(card.frontmatter.hero_image);
  return (
    <Link
      to={
        card.frontmatter.external_link === true
          ? card.frontmatter.slug
          : `/${card.fields.source}/${card.frontmatter.slug}`
      }
      className={style.card}
    >
      <article>
        <GatsbyImage
          image={image}
          alt={card.frontmatter.hero_image_alt}
          as="div"
          className={style.article_image}
        />
        <div className={style.overlayer}>
          <h2>{card.frontmatter.title}</h2>
        </div>
      </article>
    </Link>
  );
};
export default Card;
