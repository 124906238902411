import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/Layout.component";
import Header from "../../components/header/Header.component";
import Section from "../../components/layout/section/Section.component";
import CardList from "../../components/card-list/CardList.component";

const Blog = ({ data }) => {
  const page = {
    introduction: "Blog",
    header: "Blog",
    description:
      "Frontend technologies are the backbone of your website. They allow you to customize your site so that it looks and functions exactly how you want it to. Before you can start building your site, however, you'll need to decide which frontend technology is right for you. If you're new to web development, choosing a technology can be intimidating. Luckily, there are many great resources out there that will help you get started with any one of these technologies",
  };
  const cards = data.allMdx.nodes;
  return (
    <Layout pageTitle="Home">
      <Header page={page} />
      <Section>
        <CardList cards={cards} />
      </Section>
    </Layout>
  );
};
export default Blog;

export function Head() {
  return <title>Bartłomiej Nowak - frontend developer | Blog</title>;
}

export const query = graphql`
  query posts {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        id
        fields {
          source
        }
        frontmatter {
          slug
          title
          date
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
